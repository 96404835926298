import * as React from 'react';
import { useEffect } from 'react';
import loadable from '@loadable/component';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { FormTypes, sectionList } from '../../constants/queryFragments';
import { graphql, navigate } from 'gatsby';
import Cookies from 'universal-cookie';
import Header from '../../common/header/header';
import Footer from '../../common/footer/footer';
import GlobalStyles from '../../styles/globalStyles';
import BackToTopScroll from '../../components/backToTopScroll/backToTopScroll';
import { Helmet } from 'react-helmet';
import { I18nextProvider } from 'react-i18next';
import i18next from '../../i18next';
import logoSparlings from '../../images/logo-sparlings-og.png';

const CommonPageTemplate = ({ data, location }) => {
  const cookies = new Cookies();
  const [loaded, setLoaded] = React.useState(false);
  React.useLayoutEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  React.useEffect(() => {
    setLoaded(true);
    if (!location?.hash && typeof window !== 'undefined') {
      window.scrollTo(0, 0);
    }
  }, []);

  const sectionsNode = data?.allContentfulSparlings?.edges?.find((edge) =>
    cookies.get('language')
      ? edge?.node?.node_locale === cookies.get('language')
      : edge?.node?.node_locale === 'en-US'
  )?.node;

  const sectionsArray = sectionsNode?.sections;

  useEffect(() => {
    !sectionsArray && navigate('/content-unavailable');
  }, [sectionsArray]);

  return !loaded ? (
    <>
      <I18nextProvider i18n={i18next}>
        <Helmet
          htmlAttributes={{
            lang: sectionsNode?.node_locale || 'en',
          }}
        >
          <title>
            {sectionsNode?.title
              ? sectionsNode?.title + ' | Sparlings Propane'
              : 'Energy Solutions for Residential and Businesses | Sparlings Propane'}
          </title>
          {sectionsNode?.metadata && (
            <meta name="description" content={sectionsNode?.metadata}></meta>
          )}
          <meta name="twitter:image" content={logoSparlings}></meta>
          <meta property="og:image" content={logoSparlings}></meta>
          {data?.allContentfulSparlings?.edges[0]?.node?.hasIndex === false && (
            <meta name="robots" content="noindex" />
          )}
        </Helmet>
        <GlobalStyles />

        <Header />
        <>
          {sectionsArray &&
            sectionsArray?.map((section, index) => {
              if (
                section?.type &&
                !FormTypes?.includes(section?.type) &&
                sectionList?.includes(section?.type)
              ) {
                const SectionComponent = loadable(() =>
                  import(`../../components/${section?.type}/${section?.type}`)
                );
                return (
                  <SectionComponent
                    key={index}
                    sectionData={section}
                    location={location}
                  />
                );
              }
              return <></>;
            })}
        </>
        <BackToTopScroll />
        <Footer />
      </I18nextProvider>
    </>
  ) : (
    <>
      <I18nextProvider i18n={i18next}>
        <Helmet
          htmlAttributes={{
            lang: sectionsNode?.node_locale || 'en',
          }}
        >
          <title>
            {sectionsNode?.title
              ? sectionsNode?.title + ' | Sparlings Propane'
              : 'Energy Solutions for Residential and Businesses | Sparlings Propane'}
          </title>
          {sectionsNode?.metadata && (
            <meta name="description" content={sectionsNode?.metadata}></meta>
          )}
          <meta name="twitter:image" content={logoSparlings}></meta>
          <meta property="og:image" content={logoSparlings}></meta>
          {data?.allContentfulSparlings?.edges[0]?.node?.hasIndex === false && (
            <meta name="robots" content="noindex" />
          )}
        </Helmet>
        <GlobalStyles />

        <Header />
        <>
          {sectionsArray &&
            sectionsArray?.map((section, index) => {
              if (section?.type && sectionList?.includes(section?.type)) {
                const SectionComponent = loadable(() =>
                  import(`../../components/${section?.type}/${section?.type}`)
                );
                return (
                  <SectionComponent
                    key={index}
                    sectionData={section}
                    location={location}
                  />
                );
              }
              return <></>;
            })}
        </>
        <BackToTopScroll />
        <Footer />
      </I18nextProvider>
    </>
  );
};

export default CommonPageTemplate;

export const pageQuery = graphql`
  query allCommonPagesQuery(
    $pageIdentifier: String!
    $contentfulContent: Boolean!
    $contentfulContentList: Boolean!
    $contentfulTextWithImage: Boolean!
    $contentfulFaqs: Boolean!
    $contentfulListImagesWithPath: Boolean!
    $contentfulImageWithPath: Boolean!
  ) {
    allContentfulSparlings(
      filter: { identifier: { type: { eq: $pageIdentifier } } }
    ) {
      edges {
        node {
          name
          identifier {
            type
          }
          slug
          metadata
          title
          hasIndex
          node_locale
          sections {
            ...TextWithImage @include(if: $contentfulTextWithImage)
            ...PageContent @include(if: $contentfulContent)
            ...ContentList @include(if: $contentfulContentList)
            ...FAQContent @include(if: $contentfulFaqs)
            ...ImagesWithPathList @include(if: $contentfulListImagesWithPath)
            ...ImageWithPath @include(if: $contentfulImageWithPath)
          }
        }
      }
    }
  }
`;
